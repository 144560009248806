/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'gxfg3FsEvbQtDg5TwADbGe.jpg': { uri: './images/gxfg3FsEvbQtDg5TwADbGe.jpg' }
}

export default imageStaticSourcesByFileName
