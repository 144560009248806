// THIS VALUES ARE USED DURING CLIENT APP COMPILATION, PLEASE DO NOT MOVE
const initialAppId = 'app_8RS6FSCfQUHbedV5HuGRvt'
const initialProjectId = 'proj_97wgSF2jWSDp13at2MXyR1'
const initialBuildOrdersOnApp = require('./buildOrders.loaded.json') // eslint-disable-line
const initialFlattedControls = { ...require('./flattedControls.loaded.0.json') } // eslint-disable-line
const initialRunningMode = 'premium'
const initialCustomFontName = undefined
// #################

export const initialAppState = {
    appId: initialAppId,
    projectId: initialProjectId,
    buildOrders: initialBuildOrdersOnApp,
    flattedControls: initialFlattedControls,
    runningMode: initialRunningMode,
    customFontName: initialCustomFontName,
} as const
